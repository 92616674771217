//import logo from "./logo.svg";
import "./App.css";

import Hero from "./Components/Hero";

function App() {
  return (
    <div>
      <Hero />
    </div>
  );
}

export default App;
