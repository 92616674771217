import React from "react";
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className="text-white">
      <div>
        <img
          src="textlogo.png"
          alt="Capital Sales Logo"
          className="h-10 md:h-14 m-5"
        />
      </div>
      <div className="max-w-[800px] mt-[-96px] w-auto h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] md:text-2xl sm:text-xl font-bold p-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
          GIVE YOUR BUSINESS AN UNFAIR ADVANTAGE
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Build your Presense.
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-4xl sm:text-3xl text-xl font-bold py-3">
            Fast, Flexible, Sales for
          </p>
          <ReactTyped
            className="md:text-4xl sm:text-3xl text-xl font-bold sm:pl-2 pl-1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text"
            strings={[
              "Realtors",
              "Hotels",
              "Restaurants",
              "Events",
              "Apartments",
              "Gyms",
            ]}
            typeSpeed={80}
            backSpeed={30}
            loop
          />
        </div>
        <p className="pt-4 text-gray-500 font-bold md:text-2xl text-xl">
          Get in touch to see how our software and sales services can give your
          business a leg up on the competition.
        </p>

        <ul className="flex flex-col md:flex-row py-3 mx-auto">
          <a href="mailto:inquiries@CapitalSalesmn.com">
            <button className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-[200px] rounded-md text-black font-medium text-lg my-6 mx-auto py-3">
              Contact Sales
            </button>
          </a>
        </ul>
      </div>
      <div className="flex justify-center py-3">
        Copyright © 2024 Capital Sales LLC
      </div>
    </div>
  );
};

export default Hero;
